<template>
  <div class="index-scan">
    <!-- <router-link
      class="f-link"
      :to="'/cart'"
    ></router-link> -->
    <!-- index:scan START -->
    <div class="scan">
      <div
        class="headline"
        v-html="$t('terminal.index.scan.headline')"
      ></div>
      <div
        class="description"
        v-html="$t('terminal.index.scan.description')"
      ></div>
      <div class="image">
        <img
          src="@/assets/images/index-scan@3x.png"
          :alt="$t('terminal.index.scan.headline')"
        />
      </div>
    </div>
    <!-- index:scan END -->
  </div>
</template>

<script>
export default {
  name: 'index-scan',
  props: {
    step: {
      type: Number,
    },
    forward: {
      type: Function,
    },
    user: {
      type: Object,
    },
  },
};
</script>
